import React, { useEffect, memo, useRef } from 'react';
import { Table, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import styled from 'styled-components';
import { IUserProjection } from '../../logic/Models/Responses/UserAdmin/IUserProjection';
import { IRoleProjection } from '../../logic/Models/Responses/UserAdmin/IRoleProjection';
import ResizeObserver from 'rc-resize-observer';
import { VariableSizeGrid as Grid } from 'react-window';

/**
 * A virtualized table component that efficiently renders large datasets by only rendering
 * visible rows. This implementation uses react-window for virtualization and supports
 * dynamic column widths, sorting, and filtering.
 * 
 * Basic implementation comes from @see https://4x.ant.design/components/table/ but has been
 * heavily modified and improved for our specific use case
 * 
 * @template RecordType - The type of data being displayed in the table
 * @param props - Standard Ant Design Table props
 */
const VirtualTable = <RecordType extends object>(props: TableProps<RecordType>) => 
{
	const { columns } = props;
	// Track container dimensions for responsive behavior
	const [tableWidth, setTableWidth] = useState(0);
	const [tableHeight, setTableHeight] = useState(200);

	// Calculate column widths for columns without explicit widths
	const widthColumnCount = columns!.filter(({ width }) => !width).length;
	const mergedColumns = columns!.map(column => 
	{
		if (typeof column.width === 'string') 
		{
			// Convert percentage widths to pixels
			const percentage = parseFloat(column.width);
			const width = percentage * tableWidth / 100;
			return {
				...column,
				width
			};
		}
		else if (typeof column.width === 'number') 
		{
			return column;
		}

		// Distribute remaining width evenly among columns without width
		console.error('No width set for column');
		console.error(column);
		return {
			...column,
			width: Math.floor(tableWidth / widthColumnCount),
		};
	});

	// Reference to the virtual grid for scrolling and resetting
	const gridRef = useRef<any>();

	// Create a shared scroll state object between the header and body
	const [connectObject] = useState<any>(() => 
	{
		const obj = {};
		Object.defineProperty(obj, 'scrollLeft', {
			get: () => gridRef.current?.state?.scrollLeft,
			set: (scrollLeft: number) => 
			{
				if (gridRef.current) 
				{
					gridRef.current.scrollTo({ scrollLeft });
				}
			},
		});

		return obj;
	});

	// Reset the grid when table width changes
	const resetVirtualGrid = () => 
	{
		gridRef.current?.resetAfterIndices({
			columnIndex: 0,
			shouldForceUpdate: true,
		});
	};

	useEffect(() => resetVirtualGrid, [tableWidth]);

	/**
     * Renders the virtualized table body using react-window's Grid component
     * This is where the main virtualization magic happens
     */
	const renderVirtualList = (rawData: object[], { scrollbarSize, ref, onScroll }: any) => 
	{
		ref.current = connectObject;
		const totalHeight = rawData.length * 54;

		return (
			<StyledGrid
				ref={gridRef}
				className="virtual-grid"
				columnCount={mergedColumns.length}
				columnWidth={(index: number) => 
				{
					const { width } = mergedColumns[index];
					// Adjust last column width for scrollbar if needed
					return totalHeight > tableHeight && index === mergedColumns.length - 1
						? (width as number) - scrollbarSize - 1
						: (width as number);
				}}
				height={tableHeight}
				rowCount={rawData.length}
				rowHeight={() => 56}
				width={tableWidth}
				onScroll={({ scrollLeft }: { scrollLeft: number; }) => 
				{
					onScroll({ scrollLeft });
				}}
			>
				{({
					columnIndex,
					rowIndex,
					style,
				}: {
                    columnIndex: number;
                    rowIndex: number;
                    style: React.CSSProperties;
                }) => (
					<td
						className={`virtual-table-cell virtual-table-cell-${rowIndex % 2 ? 'odd' : 'even'} ${columnIndex === mergedColumns.length - 1 ? 'virtual-table-cell-last' : ''}`}
						style={style}
					>
						{/* Render cell content using column render function or raw value */}
						{mergedColumns[columnIndex].render
							? mergedColumns[columnIndex].render(
								(rawData[rowIndex] as any)[(mergedColumns as any)[columnIndex].dataIndex],
								(rawData[rowIndex] as any), rowIndex)
							: (rawData[rowIndex] as any)[(mergedColumns as any)[columnIndex].dataIndex]}
					</td>
				)}
			</StyledGrid>
		);
	};

	return (
		<ResizeObserver
			onResize={({ width, height }) => 
			{
				setTableWidth(width);
				setTableHeight(height - 55); // Adjust for header height
			}}
		>
			<div style={{ width: '100%', height: '100%', overflowY: 'auto', overflowX: 'hidden', flexGrow: 1 }}>
				<Table
					{...props}
					className="virtual-table"
					columns={mergedColumns}
					pagination={false}
					components={{
						body: renderVirtualList,
					}} />
			</div>
		</ResizeObserver>
	);
};

// Styled component for the virtual grid with consistent cell styling
const StyledGrid = styled(Grid)`
    // ...existing styles...
`;

/**
 * A memoized virtual table specifically for user data
 * This component prevents unnecessary re-renders when parent components update
 */
export const UsersTable = memo(({ columns, dataSource }: { columns: ColumnsType<IUserProjection>; dataSource: IUserProjection[]; }) => 
{
	return (
		<VirtualTable
			columns={columns}
			dataSource={dataSource}
			pagination={false}
			rowKey={'Id'}
			scroll={{ y: 100 }} />
	);
});
UsersTable.displayName = 'UsersTable';

/**
 * A memoized virtual table specifically for role data
 * Similar to UsersTable but with role-specific typing
 */
export const RolesTable = memo(({ columns, dataSource }: { columns: ColumnsType<IRoleProjection>; dataSource: IRoleProjection[]; }) => (
	<VirtualTable
		columns={columns}
		dataSource={dataSource}
		pagination={false}
		rowKey={'Id'}
		scroll={{ y: 300 }} />
));
RolesTable.displayName = 'RolesTable';
