import React, { useMemo } from 'react';
import { themeGet } from '@styled-system/theme-get';
import styled, { useTheme } from 'styled-components';
import { DatePicker, InputNumber, Select } from 'antd';
import moment from 'moment';
import { cropOptions, IConfirmedField, NumberInput } from './FieldConfirmation';
import { IOptionItem } from '../../../components/Dropdown/IOptionItem';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { dynamicSort } from '../../../logic/Utility/Utils';
import { IHybridResponse } from '../../../logic/Models/Responses/SeedResponse';
import { ReactComponent as DeleteIcon } from '../../../assets/images/Trashcan.svg';
import { IconButton } from '../../../components/IconButton/IconButton';

const StyledDatePicker = styled(DatePicker)`
	border-radius: 4px;
	width: 88%;
	padding: 2px 5px 2px;
	&:focus {
		outline: none;
		box-shadow: 0 0 1pt 1pt white;
	}
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
	.ant-picker-focused {
		border-color: black;
	}
`;

const StyledDatePickerPanel = styled.div`
	.ant-picker-today-btn {
		color: ${themeGet('colors.primary')};
	}
	.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
		border-width: 1px;
		border-style: solid;
		border-color: ${themeGet('colors.secondary')};
	}
	.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
		background: ${themeGet('colors.primary')};
	}
`;

const StyledSelect = styled(Select)`
	width: 100%;
	.ant-select-selector {
		border-radius: 4px !important;
		border-color: ${themeGet('colors.lightestGrey')} !important;
		//background-color: ${themeGet('colors.darkGrey')} !important;
		//color: white;
		font-family: 'Roboto';
		font-size: ${themeGet('fontSizes.tiny')};
		font-weight: ${themeGet('fontWeights.bold')};
		height: 25px !important;
		align-items: center;
	}

	.ant-select-selection-search {
		display: flex;
		align-items: center;
	}

	.ant-select-dropdown {
		font-size: ${themeGet('fontSizes.tiny')};
	}

	svg path {
		fill: ${themeGet('colors.darkGrey')};
		stroke: ${themeGet('colors.darkGrey')};
		stroke-width: 100;
	}
`;

const StyledIconButton = styled(IconButton)`
	border-radius: 25px;
	height: 80%;
	@media (hover: none) {
		width: 35px;
	}
	@media (hover: hover) {
		width: 40px;
	}
`;

export interface IConfirmationFieldRowProps
{
	field: IConfirmedField;
	hybridList: IHybridResponse[];
	lastItem: boolean;
	changeFieldCrop: (fieldId: string, newCropId: string) => void;
	changeFieldHybrid: (fieldId: string, updatedHybridId: string, previousHybridId: string) => void;
	disabledDates: (currentDate: moment.Moment) => boolean;
	editHybridAcres: (fieldId: string, hybridId: string, updatedValue: Number) => void;
	editHybridRate: (fieldId: string, hybridId: string, updatedValue: Number) => void;
	editPlantingDate: (fieldId: string, updatedValue: string) => void;
	removeHybridFromField: (fieldId: string, hybridToRemoveId: string) => void;
}

export const ConfirmationFieldRow = (props: IConfirmationFieldRowProps) =>
{
	const {
		field,
		hybridList,
		lastItem,
		changeFieldCrop,
		changeFieldHybrid,
		disabledDates,
		editHybridAcres,
		editHybridRate,
		editPlantingDate,
		removeHybridFromField,
	} = props;

	const theme = useTheme();
	const dateFormat = 'MM/DD/YYYY';

	const hybridOptions: IOptionItem[] = useMemo(() =>
	{
		if (hybridList && hybridList.length > 0)
		{
			const optionsList: IOptionItem[] = [];

			hybridList.sort(dynamicSort('Name')).sort(dynamicSort('RelativeMaturity')).forEach(h =>
			{
				optionsList.push({ label: h.Name, value: h.Id });
			});

			return optionsList;
		}
	},[hybridList]);

	const changeCrop = (updatedCropId: string) =>
	{
		changeFieldCrop(field.Id, updatedCropId);
	};

	// Changes an existing Hybrid to a new Hybrid
	const changeHybrid = (updatedHybridId: string, previousHybridId: string) =>
	{
		changeFieldHybrid(field.Id, updatedHybridId, previousHybridId);
	};

	const removeHybrid = (hybridToRemoveId: string) =>
	{
		removeHybridFromField(field.Id, hybridToRemoveId);
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				fontSize: 12,
				paddingLeft: 10,
				paddingRight: 10,
				paddingTop: 15,
				paddingBottom: 15,
				minHeight: 40,
				alignItems: 'center',
				borderWidth: 1,
				borderColor: theme.colors.lightestGrey,
				borderBottomStyle: 'solid',
				borderLeftStyle: 'solid',
				borderRightStyle: 'solid',
				borderBottomLeftRadius: lastItem ? 6 : 0,
				WebkitBorderBottomRightRadius: lastItem ? 6 : 0,
			}}
		>
			<div style={{ width: '12%', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
				<span>{field.Name}</span>
			</div>
			<div style={{ width: '12%', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
				<span>{field.FarmName}</span>
			</div>
			<div style={{ width: '12%', textAlign: 'left' }}>
				<div style={{ width: '70%' }}>
					<Dropdown
						variant='outlinednarrownopad'
						options={cropOptions}
						onSelect={changeCrop}
						selected={field.CropId}
						placeholder='Select a Crop'
						className='Crop_Dropdown'
					/>
				</div>
			</div>
			<div style={{ width: '5%', textAlign: 'left' }}>
				<span>{field.Acres}</span>
			</div>
			<div style={{ width: '45%', textAlign: 'left' }}>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					{
						field.HybridsToConfirm.map((h,index) =>
						{
							return(
								<div
									key={field.Id + h.HybridId}
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginBottom: index + 1 === field.HybridsToConfirm.length ? 0 : 10,
										alignItems: 'center'
									}}
								>
									<div style={{ width: '30%'}}>
										<StyledSelect
											showSearch
											optionFilterProp='label'
											options={hybridOptions}
											onSelect={(newValue: string) => changeHybrid(newValue, h.HybridId)}
											value={h.HybridId}
											placeholder={'Select a Hybrid'}
											className='Hybrid_Dropdown'
										/>
									</div>
									<div style={{ marginLeft: 10, width: 100, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
										<div style={{ marginRight: 5 }}>Ac: </div>
										<NumberInput
											style={{ fontSize: 12, width: '90%', height: 25, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
											className={'Hybrid_Acres_Input'}
											type='number'
											placeholder='Add'
											status={h.HybridId === null ? undefined : h.Area > 0 ? undefined : 'error'}
											value={h.Area}
											onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
												editHybridAcres(field.Id, h.HybridId, evt.currentTarget.value ? 
													Number(evt.currentTarget.value) 
													: Number(h.Area))}
											onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
												editHybridAcres(field.Id, h.HybridId, evt.target && (evt.target as HTMLInputElement).value ? 
													Number((evt.target as HTMLInputElement).value)
													: Number(h.Area))}
										/>
									</div>
									<div style={{ marginLeft: 10, width: 100, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
										<div style={{ marginRight: 5 }}>Rate: </div>
										<NumberInput
											style={{
												fontSize: 12,
												width: '90%',
												height: 25,
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'center',
											}}
											status={h.HybridId === null ? undefined : h.Rate > 0 ? undefined : 'error'}
											className={'Hybrid_Rate_Input'}
											type='number'
											placeholder='Add Rate'
											value={h.Rate}
											onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
												editHybridRate(field.Id, h.HybridId, evt.currentTarget.value ? 
													Number(evt.currentTarget.value) 
													: Number(h.Rate))}
											onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
												editHybridRate(field.Id, h.HybridId, evt.target && (evt.target as HTMLInputElement).value ? 
													Number((evt.target as HTMLInputElement).value)
													: Number(h.Rate))}
										/>
									</div>
									<div style={{ marginLeft: 10, width: 15, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
										{
											// We do not want to put a delete button on the new hybrid row
											h.HybridId !== null &&
											<StyledIconButton
												className='delete-button'
												onClick={() => removeHybrid(h.HybridId)}
												tooltip='Delete'
												placement='right'
												fillcolor={theme.colors.darkGrey}
												hoverbgcolor={theme.colors.lightestGrey}
											>
												<DeleteIcon style={{ position: 'relative', top: -1 }} />
											</StyledIconButton>
										}
									</div>
								</div>
							);
						})
					}
				</div>
			</div>
			<div style={{ width: '15%', textAlign: 'left' }}>
				<StyledDatePicker
					className={'Planting_Date_Input'}
					placeholder={'mm/dd/yyyy'}
					format={dateFormat}
					value={field.PlantingDate ? moment(field.PlantingDate) : undefined}
					disabledDate={disabledDates}
					onChange={(value: moment.Moment) => editPlantingDate(field.Id, value ? value.format(dateFormat) : '')}
					panelRender={originalPanel =>
					{
						return (
							<StyledDatePickerPanel className='StyledDatePickerPanel'>
								{originalPanel}
							</StyledDatePickerPanel>
						);
					}}
				/>
			</div>
		</div>
	);
};