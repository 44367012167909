import React, { useState } from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { ReactComponent as Gameplan } from '../../assets/images/Gameplan.svg';
import { ReactComponent as Priority } from '../../assets/images/LowPriority.svg';
import { ReactComponent as Download } from '../../assets/images/Download.svg';
import { ReactComponent as Contract } from '../../assets/images/DocumentCheck.svg';
import { Dropdown, MenuProps, Tooltip } from 'antd';
import { Button } from '../../components/Button/Button';

const ActionButton = styled(Button)<{menuExists: boolean, isCustom: boolean}>`
	width: ${props => props.width ? props.width : '130px'};
	height: ${props => props.height ? props.height : '95px'};
	margin: 5px 0px;
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-width: 1px;
	border-color: ${themeGet('colors.darkGrey')};
	border-radius: ${props => props.isCustom? '2px' : '4px'};

	:hover {
		background-color: ${themeGet('colors.white')} !important;
		border-color: ${themeGet('colors.primary')};

		div {
			color: ${themeGet('colors.primary')};
		}

		div > svg {
			fill: ${themeGet('colors.primary')};
		}
	}

	:focus {
		background-color: ${props => props.menuExists ? props.isCustom ? props.theme.colors.lightestGrey + ' !important' : props.theme.colors.primary + ' !important' : '' } ;
		border-color: ${props => props.menuExists ? props.theme.colors.primary : '' };

		div {
			color: ${props => props.menuExists ? props.theme.colors.white : '' };
		}

		div > svg {
			fill: ${props => props.menuExists ? props.theme.colors.white : '' };
		}
	}
`;

const ActionIcon = styled.div`
	svg {
		fill: ${themeGet('colors.darkGrey')};
	}
`;

const ActionLabel = styled.div`
	font-size: 14px;
	font-weight: 400;
	text-align: center;
	color: ${themeGet('colors.darkGrey')};
	margin-top: 5px;
`;

type FieldListActionDrawerButtonType = 'gameplan' | 'priority' | 'agriclime' | 'enogen' | 'confirmation' | 'custom';

interface IFieldListActionDrawerButtonProps
{
	variant: FieldListActionDrawerButtonType;
	onClick?: () => void;
	menu?: MenuProps;
	disabled?: boolean;
	className?: string;
	icon?: React.ReactNode;
}

export const FieldListActionDrawerButton = (props: IFieldListActionDrawerButtonProps) =>
{
	const [showGameplanMenu, setShowGameplanMenu] = useState(false);

	const renderIcon = () =>
	{
		switch(props.variant)
		{
			case 'gameplan':
				return <Gameplan />;
			case 'priority':
				return <Priority />;
			case 'agriclime':
				return <Download />;
			case 'enogen':
				return <Contract />;
			case 'confirmation':
				return <Contract />;
			case 'custom':
				return props.icon;
		}
	};

	const renderLabel = () =>
	{
		switch(props.variant)
		{
			case 'gameplan':
				return 'Create Proposal';
			case 'priority':
				return 'Harvest Priority';
			case 'agriclime':
				return 'AgriClime';
			case 'enogen':
				return 'Enogen Contract';
			case 'confirmation':
				return 'Confirm Fields';
		}
	};

	const innerContent =
		<ActionButton
			menuExists={props.menu ? true : false}
			variant={props.variant === 'custom' ? 'light' : 'outlined'}
			height={props.variant === 'custom' ? '32px' : undefined}
			width={props.variant === 'custom' ? '32px' : undefined}
			onClick={props.onClick}
			style={{ pointerEvents: props.disabled ? 'none' : 'auto' }}
			className={props.className}
			disabled={props.disabled}
			isCustom={props.variant === 'custom'}
		>
			<ActionIcon style={{ opacity: props.disabled ? 0.3 : 1 }}>
				{renderIcon()}
			</ActionIcon>
			{
				props.variant !== 'custom' ?
					<ActionLabel style={{ opacity: props.disabled ? 0.3 : 1 }}>
						{renderLabel()}
					</ActionLabel>
					:
					<></>
			}
		</ActionButton>;

	// If we have a menu, wrap the content in a dropdown.
	if(props.menu)
	{
		return (
			<Tooltip title={props.disabled ? 'Coming soon!' : ''} placement='right'>
				<span style={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }}>
					<Dropdown
						menu={props.menu}
						trigger={['click']}
						disabled={props.disabled && !props.menu}
						onOpenChange={(visible) => setShowGameplanMenu(visible)}
						open={showGameplanMenu}
					>
						{innerContent}
					</Dropdown>
				</span>
			</Tooltip>
		);	
	}

	return (
		<Tooltip title={props.disabled ? 'Coming soon!' : ''} placement='right'>
			<span style={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }}>
				{innerContent}
			</span>
		</Tooltip>
	);
};